// Swiper. core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay])

//Vimeo
import Player from '@vimeo/player'

$(function () {
	const slideInicio = new Swiper('.slide-inicio', {
		autoplay: true,
		loop: true,
		lazy: true,
		allowTouchMove: true,
		pauseOnMouseEnter: true,
		disableOnInteraction: false,
		navigation: {
			nextEl: '.inicio-slide-next',
			prevEl: '.inicio-slide-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			581: {
				allowTouchMove: false,
			},
		},
	})
	slideInicio.on('slideChange', function () {
		let slide = this.slides[this.activeIndex]
		if (slide) {
			let color = parseInt(slide.dataset.color)

			$('.inicio-slide-prev, .inicio-slide-next').removeClass(color ? 'color-0' : 'color-1')
			$('.inicio-slide-prev, .inicio-slide-next').addClass(color ? 'color-1' : 'color-0')
		}
	})
	slideInicio.on('slideChangeTransitionEnd', function (swiper) {
		swiper.slides.forEach((slide) => {
			let vimeo_inicio = slide.querySelector('.vimeo_inicio')
			let video_inicio = slide.querySelector('.video_inicio')
			let btn = slide.querySelector('button.play')

			if (vimeo_inicio) {
				var p = new Player(vimeo_inicio)

				p.getPaused().then(function (paused) {
					if (!paused) {
						p.pause()
							.then(function () {
								$(btn).removeClass('click')
								console.log('video pausado')
							})
							.catch(function (error) {
								console.log('video error', error)
							})
					}
				})
			}
			if (video_inicio) {
				if (!video_inicio.paused) {
					video_inicio.pause()
				}
			}
		})

		var slide_active = swiper.slides[swiper.activeIndex]
		var vimeo_slide_active = slide_active.querySelector('.vimeo_inicio')
		var video_slide_active = slide_active.querySelector('.video_inicio')
		var btn_slide_active = slide_active.querySelector('button.play')

		if (vimeo_slide_active) {
			let player = new Player(vimeo_slide_active)
			player
				.play()
				.then(function () {
					$(btn_slide_active).addClass('click')
					console.log('video iniciado')
				})
				.catch(function (error) {
					console.log('video error', error)
				})
		}

		if (video_slide_active) {
			if (video_slide_active.paused) {
				video_slide_active.play()
				$(btn_slide_active).addClass('click')
				console.log('video iniciado')
			}
		}
	})

	$('.item-video button.play').on('click', function () {
		var _this = this
		let vimeo_slide_active = $(_this).siblings('.vimeo_inicio')
		let video_slide_active = $(_this).siblings('.video_inicio')
		vimeo_slide_active = vimeo_slide_active.length ? vimeo_slide_active[0] : null
		video_slide_active = video_slide_active.length ? video_slide_active[0] : null

		if (vimeo_slide_active) {
			let player = new Player(vimeo_slide_active)

			player.getPaused().then(function (paused) {
				if (!paused) {
					player
						.pause()
						.then(function () {
							$(_this).removeClass('click')
							console.log('video pausado')
						})
						.catch(function (error) {
							console.log('video error', error)
						})
				} else {
					player
						.play()
						.then(function () {
							$(_this).addClass('click')
							console.log('video iniciado')
						})
						.catch(function (error) {
							console.log('video error', error)
						})
				}
			})
		}

		if (video_slide_active) {
			if (video_slide_active.paused) {
				video_slide_active.play()
				$(_this).addClass('click')
				console.log('video iniciado')
			} else {
				video_slide_active.pause()
				$(_this).removeClass('click')
				console.log('video pausado')
			}
		}
	})

	$('.item-video button.mute').on('click', function () {
		var _this = this
		let vimeo_slide_active = $(_this).siblings('.vimeo_inicio')
		let video_slide_active = $(_this).siblings('.video_inicio')
		vimeo_slide_active = vimeo_slide_active.length ? vimeo_slide_active[0] : null
		video_slide_active = video_slide_active.length ? video_slide_active[0] : null

		if (vimeo_slide_active) {
			let player = new Player(vimeo_slide_active)
			player.getVolume().then(function (volume) {
				console.log(volume)
			})
		}

		if (video_slide_active) {
			console.log(video_slide_active.muted)
			if (!video_slide_active.muted) {
				video_slide_active.muted = true
				$(_this).removeClass('click')
				console.log('video silenciado', video_slide_active.muted)
			} else {
				video_slide_active.muted = false
				$(_this).addClass('click')
				console.log('video con volumen', video_slide_active.muted)
			}
		}
	})

	/*
	setTimeout(function(){
		var h_slide_vive = $('#vive .swiper-wrapper').height();
		console.log(h_slide_vive);

		$('#vive .swiper-wrapper .swiper-slide').css('height', h_slide_vive);
	},500);
	*/

	const slider_vive = new Swiper('.slider-vive', {
		speed: 1000,
		autoHeight: true,
		lazy: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 20,
				freeMode: true,
				// centeredSlidesBounds: true,
			},
			// when window width is >= 640px
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
				freeMode: true,
			},
			980: {
				slidesPerView: 3,
				spaceBetween: 40,
				freeMode: false,
			},
		},
		navigation: {
			nextEl: '#vive .section-slide-next',
			prevEl: '#vive .section-slide-prev',
		},
	})

	const slider_cocrea = new Swiper('.slider-cocrea', {
		speed: 1000,
		lazy: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 20,
				freeMode: true,
				// centeredSlidesBounds: true,
			},
			// when window width is >= 640px
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
				freeMode: true,
			},
			980: {
				slidesPerView: 3,
				spaceBetween: 40,
				freeMode: false,
			},
		},
		navigation: {
			nextEl: '#cocrea .section-slide-next',
			prevEl: '#cocrea .section-slide-prev',
		},
	})

	const slider_aprende = new Swiper('.slider-aprende', {
		speed: 1000,
		lazy: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 20,
				freeMode: true,
				// centeredSlidesBounds: true,
			},
			// when window width is >= 640px
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
				freeMode: true,
			},
			980: {
				slidesPerView: 3,
				spaceBetween: 40,
				freeMode: false,
			},
		},
		navigation: {
			nextEl: '#aprende .section-slide-next',
			prevEl: '#aprende .section-slide-prev',
		},
	})

	const slider_cultura_sostenible = new Swiper('.slider-cultura-sostenible', {
		speed: 1000,
		lazy: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 20,
				freeMode: true,
				// centeredSlidesBounds: true,
			},
			// when window width is >= 640px
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
				freeMode: true,
			},
			980: {
				slidesPerView: 3,
				spaceBetween: 40,
				freeMode: false,
			},
		},
		navigation: {
			nextEl: '#cultura-sostenible .section-slide-next',
			prevEl: '#cultura-sostenible .section-slide-prev',
		},
	})

	const slider_impulsa = new Swiper('.slider-impulsa', {
		speed: 1000,
		lazy: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 20,
				freeMode: true,
				// centeredSlidesBounds: true,
			},
			// when window width is >= 640px
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
				freeMode: true,
			},
			980: {
				slidesPerView: 3,
				spaceBetween: 40,
				freeMode: false,
			},
		},
		navigation: {
			nextEl: '#impulsa .section-slide-next',
			prevEl: '#impulsa .section-slide-prev',
		},
	})

	const slider_descubre = new Swiper('.slider-descubre', {
		speed: 1000,
		lazy: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 20,
				freeMode: true,
				// centeredSlidesBounds: true,
			},
			// when window width is >= 640px
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
				freeMode: true,
			},
			980: {
				slidesPerView: 3,
				spaceBetween: 40,
				freeMode: false,
			},
		},
		navigation: {
			nextEl: '#impulsa .section-slide-next',
			prevEl: '#impulsa .section-slide-prev',
		},
	})
})
